import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ShopIndex = ({ data }) => {
  const shopItems = data.allMarkdownRemark.edges

  if (shopItems.length === 0) {
    return (
      <Layout>
        <SEO title="Nos actualités" description="Trouvez toutes les actualités de Air water System." />
        <p>
          No shop posts found. Add markdown posts to "content/shop" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout>
      <SEO title="Notre boutique" description="Trouvez tous les produits vendus par Air water System." />
      <section className="section">
        <div className="container">
          <h1 className="title is-1">Notre boutique</h1>
           <div className="columns">
            {shopItems.map(shopItem => {
              const title = shopItem.node.frontmatter.title || shopItem.node.fields.slug

              return (
                <article
                  className="post-list-item column is-2 mb-6"
                  itemScope
                  itemType="http://schema.org/Article"
                >
                  <figure className="has-text-centered" width="256" height="256">
                    <img className="shop-preview" src={shopItem.node.frontmatter.featuredimage}></img>
                  </figure>
                  <div className="title is-5">
                    {title}
                  </div>
                  <p className="subtitle has-text-success-dark">
                    {(shopItem.node.frontmatter.price).toLocaleString('fr-FR', {style: 'currency', currency: 'EUR'})}
                  </p>
                  <Link to={shopItem.node.fields.slug} className="button is-primary is-small is-fullwidth">
                    <strong>Voir l'article</strong>
                  </Link>
                </article>
              )
            })}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ShopIndex

export const pageQuery = graphql`
  query Shop{
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, filter: {frontmatter: {templateKey: {eq: "shop-item"}}}) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            templateKey
            title
            price
            date(formatString: "DD MMMM YYYY", locale: "fr")
            featuredimage
          }
        }
      }
    }
  }
`